
import { contentsService, StaticContent } from "@/services/contents.service";
import { defineComponent } from "vue";
import { Paginator, paginatorService } from "@/services/paginator.service";
import PageHeadline from "@/common/PageHeadline.vue";
import { OpenPosition } from "@/modules/join-us/position-model";
import { setPageDescription, setPageTitle } from "@/common/meta.utils";
import LaunchYourProduct from "@/components/LaunchYourProduct.vue";

export default defineComponent({
  name: "OpenPositionsPaginator",
  components: { LaunchYourProduct, PageHeadline },
  props: {},
  data() {
    return {
      page: undefined as StaticContent | undefined,
      paginator: undefined as Paginator<OpenPosition> | undefined,
      pageNo: 1,
      pageLimit: 12,
      list: [] as OpenPosition[],
    };
  },
  mounted() {
    this.loadData();
  },

  methods: {
    loadData() {
      contentsService.findBySlug("page-join-us").then((page) => {
        this.page = page;
        setPageTitle(page?.title);
        setPageDescription(page?.lead);
      });
      paginatorService
        .paginator("open-positions", {
          page: this.pageNo,
          limit: this.pageLimit,
        })
        .then((paginator) => {
          this.paginator = paginator as Paginator<OpenPosition>;
          this.list.push(...this.paginator.list);
        });
    },
    showMore() {
      this.pageNo += 1;
      paginatorService
        .paginator("open-positions", {
          page: this.pageNo,
          limit: this.pageLimit,
        })
        .then((paginator) => {
          this.paginator = paginator as Paginator<OpenPosition>;
          this.list.push(...this.paginator.list);
        });
    },
    goto(item: OpenPosition) {
      this.$router.push("/join-us/" + item.slug);
    },
  },
});
